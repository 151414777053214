export default [
  {
    title: 'İlan Yönetimi',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Kategori Yönetimi',
    route: 'second-page',
    icon: 'FileIcon',
  },
  // {
  //   title: 'Kullanıcı işlemleri',
  //   route: 'appResult',
  //   icon: 'FileIcon',
  // },
]
